var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),(_vm.tableData.length > 0)?[_c('div',{staticClass:"row",attrs:{"id":"caseComment"}},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},_vm._l((_vm.tableData),function(case_comment){return _c('div',{key:case_comment.id,staticClass:"card"},[_c('div',{staticClass:"media"},[_c('a',{staticClass:"pr-3",attrs:{"href":"#"}},[_c('span',{attrs:{"data-letters":case_comment.member.first_name.toUpperCase().charAt(0) +
                    case_comment.member.last_name.toUpperCase().charAt(0)}})]),_c('div',{staticClass:"media-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex"},[_c('h5',[_vm._v(" "+_vm._s(case_comment.member.first_name + " " + case_comment.member.last_name)+" ")])])]),_c('div',{domProps:{"innerHTML":_vm._s(case_comment.comment)}}),_c('div',[_c('b-form-checkbox',{staticClass:"mb-1",attrs:{"switch":"","checked":case_comment.is_approved == 1 ? true : false},on:{"change":function($event){return _vm.updateCommentStatus(case_comment.id)}}})],1),(case_comment.replies.length > 0)?_vm._l((case_comment.replies),function(reply){return _c('div',{key:reply.id,staticClass:"media mt-3"},[_c('a',{staticClass:"pr-3",attrs:{"href":"#"}},[_c('span',{attrs:{"data-letters":reply.member.first_name.toUpperCase().charAt(0) +
                          reply.member.last_name.toUpperCase().charAt(0)}})]),_c('div',{staticClass:"media-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex"},[_c('h5',[_vm._v(" "+_vm._s(reply.member.first_name + " " + reply.member.last_name)+" ")])])]),_c('div',{domProps:{"innerHTML":_vm._s(reply.comment)}}),_c('div',[_c('b-form-checkbox',{staticClass:"mb-1",attrs:{"switch":"","checked":reply.is_approved == 1 ? true : false},on:{"change":function($event){return _vm.updateCommentStatus(reply.id)}},model:{value:(reply.is_approved),callback:function ($$v) {_vm.$set(reply, "is_approved", $$v)},expression:"reply.is_approved"}})],1),(reply.replies.length > 0)?_vm._l((reply.replies),function(nested_reply){return _c('div',{key:nested_reply.id,staticClass:"media mt-3"},[_c('a',{staticClass:"pr-3",attrs:{"href":"#"}},[_c('span',{attrs:{"data-letters":nested_reply.member.first_name
                                  .toUpperCase()
                                  .charAt(0) +
                                nested_reply.member.last_name
                                  .toUpperCase()
                                  .charAt(0)}})]),_c('div',{staticClass:"media-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex"},[_c('h5',[_vm._v(" "+_vm._s(nested_reply.member.first_name + " " + nested_reply.member.last_name)+" ")])])]),_c('div',{domProps:{"innerHTML":_vm._s(nested_reply.comment)}}),_c('div',[_c('b-form-checkbox',{staticClass:"mb-1",attrs:{"switch":"","checked":nested_reply.is_approved == 1
                                    ? true
                                    : false},on:{"change":function($event){return _vm.updateCommentStatus(nested_reply.id)}},model:{value:(nested_reply.is_approved),callback:function ($$v) {_vm.$set(nested_reply, "is_approved", $$v)},expression:"nested_reply.is_approved"}})],1)])])}):_vm._e()],2)])}):_vm._e()],2)])])}),0)])])])]:[_c('div',{staticClass:"d-flex justify-content-center"},[_c('img',{attrs:{"src":require("../../../../public/no-search-found.png"),"width":"600","height":"300","alt":""}})])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }